<template>
  <div class="headerm">
    <div class="logo">
      <img src="../assets/logo/BC.png" alt="" />
    </div>
    <!-- <div class="xlk">
      <el-dropdown @command="handleClick">
        <span class="el-dropdown-link">
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="R">樂滿門</el-dropdown-item>
            <el-dropdown-item command="S">森美奧</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      company_sn: "R",
    };
  },
  created() {
    if (sessionStorage.getItem("company_sn") != null) {
      this.company_sn = sessionStorage.getItem("company_sn");
    }
  },
  methods: {
    handleClick(command) {
      this.company_sn = command;
      sessionStorage.setItem("company_sn", command);
      this.$router.push({ path: "/home_m" });
      var t;
      clearTimeout(t);
      var _that = this;
      t = setTimeout(function () {
        _that.$router.go(0);
      }, 500);
    },
  },
};
</script>

<style>
.headerm {
  display: flex;
}
.logo {
  margin: 0px 10px;
  width: 80vw;
  height: 84px;
  text-align: left;
}
.logo img {
  width: 220px;
  height: 84px;
}
.xlk {
  margin: 5vw;
}
</style>