<template>
  <div class="footer">
    <div class="lxwm">
      <div class="">
        <img class="wx" src="../assets/picture/wx.png" alt="" />
      </div>
      <div class="lxwm-righy">
        <div class="ducontant c093"  @click="callPhone('18122828713')">电话：18122828713</div>
        <div class="ducontant">地址：惠州市惠城区翠竹二路中骏雍景湾9栋2单元504</div>
      </div>
    </div>
     <a class="bats" href="http://beian.miit.gov.cn">粤ICP备2021027625号</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company_sn: "R",
    };
  },
  created() {},
  methods: {
        callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
  },
};
</script>

<style>
.footer {
  width: 100vw;
  height: 280px;
  background-image: url(http://14.116.154.181:8890/bisi_website/gw/280.png);
}
.lxwm{
  display: flex;
}
.wx{
  width: 140px;
  height: 140px;
  margin: 20px;
}
.lxwm-righy{
  margin-top: 50px;
}
.c093{
  color: #bdbde0;
}
.ducontant {
  color: #b5b5b9;
  height: 24px;
  line-height: 24px;
  text-align: left;
}
.bats {
  font-size: 13px;
  height: 130px;
  line-height: 130px;
  color: #ffffff;
}

</style>