<template>
  <div>
      <Header></Header>

    <!-- 大图 -->
    <div class="dt">
      <div class="dt_text">
        <div class="text1">彼思生产ERP</div>
        <div class="text2">生产管理自动化，功能齐全</div>
      </div>
    </div>

    <div class="question-box">
      <div class="question-head">解决企业以下问题</div>
      <div class="question"></div>
      <div class="question-wz">
        <div class="question-title">决策慢</div>
        <div class="question-desc">
          管理者无法及时掌握一手准确信息，导致决策需靠经验，加大决策风险
        </div>
      </div>
      <div class="question-wz">
        <div class="question-title">管控难</div>
        <div class="question-desc">
          项目周期长，涉及到立项、投标、合同、设计、
          采购、施工、发票等过程，管理环节多、难度大
        </div>
      </div>
      <div class="question-wz">
        <div class="question-title">利润低</div>
        <div class="question-desc">
          上游产业投资价格压低、建企之间激烈竞争，用工成本、采购成本不断增加，导致利润持续走低
        </div>
      </div>
      <div class="question-wz">
        <div class="question-title">要求高</div>
        <div class="question-desc">
          质量终身责任制、四流合一、四库一平台等对施工企业信息监管和质量要求提出更高的要求
        </div>
      </div>
    </div>

 

    <!-- 软件流程图 -->
    <div>
      <div class="question-head">功能模块简介</div>
      <img class="lct"
        src="http://14.116.154.181:8890/bisi_website/gw/erp-lc2.png"
        alt=""
      />
    </div>

    <!-- 系统页面 -->
    <div class="xtym">
      <div class="question-head">系统页面</div>
      <img
        class="xt-img"
        src="http://14.116.154.181:8890/bisi_website/gw/erp-tp2.png"
        alt=""
      />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer_m";
import Header from "@/components/Header_m";
export default {
  name: "retail",
  data() {
    return {};
  },
    components: { Header, Footer },
};
</script>

<style scoped>
.question-box {
  width: 100vw;
  margin: 0 auto;
}
.question-head {
  margin: 10px;
  font-size: 21px;
  color: #293a6c;
}
.question {
  background-image: url("http://14.116.154.181:8890/bisi_website/gw/questionimg2.gif");
  height: 300px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}
.question-wz {
  margin: 10px;
}
.question-title {
  width: 150px;
  height: 35px;
  font-size: 21px;
  font-weight: 600;
  color: #057df6;
  line-height: 40px;
  text-align: left;
}
.question-desc {
  /* width: 353px; */
  height: 50px;
  font-size: 16px;
  color: #0a1735;
  text-align: left;
}

/* 大图 */
.dt {
  height: 45vw;
  width: 100vw;
  background-image: url("http://14.116.154.181:8890/bisi_website/gw/banner_erp.jpg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}
.dt_text {
  margin: 0 auto;
  width: 80vw;
  text-align: left;
}
.text1 {
  padding: 46px 0px 15px 0px;
  font-size: 26px;
  color: #ffffff;
  font-weight: 700;
  /* font-family: cursive; */
}
.text2 {
  font-weight: 700;
  padding-bottom: 20px;
  font-size: 14px;
  color: #ffffff;
}

.lct{
    width: 100vw;
}
.xt-img{
     width: 98vw;
     margin: 10px 1vw ;
}
.xtym{
    margin-bottom: 20px;
}



</style>